$snnRuleMinWidth: 120px;
$snnRuleZIndexHigh: 1030;
$snnRuleZIndexNormal: 1029;
$snnRuleBorderFocus: 1px solid #C6C2C1;

.snnRule {
  .row {
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .airline, .flightNumber, .aircraftType, .registration, .dep, .arr, .snn, .altn, .era, .countryCode, .filter {
    min-width: $snnRuleMinWidth;
    &.cx-dropdown, &.activeStatus {
      z-index: $snnRuleZIndexHigh !important;
    }
  }
  .flightNumberCol, .aircraftTypeCol, .registrationCol {
    flex: 1.5;
  }
  .depCol, .arrCol {
    flex: 2;
  }
  .altnCol, .eraCol {
    flex: 1;
  }
  .filterCol {
    width: 120px;
  }
  .activeStatus {
    min-width: 100px;
    z-index: $snnRuleZIndexNormal !important;
    .cx-form-input:focus, .focused {
      border: $snnRuleBorderFocus !important;
    }
  }
}

.unClickable {
  opacity: 0.5;
  cursor: not-allowed ;
}

.snnDeleteBtn{
  cursor: pointer;
}
