//General Component
.cx-form-control {
  height: 48px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  z-index: 0;
}

.cx-form-control-text-area { 
  width: 100%;
  border-radius: 4px;
  position: relative;
  z-index: 0;
}

.cx-form-input {
  font-family: "RobotoMono-Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #2D2D2D;

  padding-top: 22px;
  padding-left: 8px;
  padding-bottom: 7px;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #C6C2C1;
  border-radius: 4px;
  cursor: text;
}

.cx-form-input-without-label {
  font-family: "RobotoMono-Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #737373;

  padding-top: 15px;
  padding-left: 8px;
  padding-bottom: 14px;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #C6C2C1;
  border-radius: 4px;
}

.cx-form-helper {
  margin-bottom: 8px;
  font-family: "CathaySans_Rg";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.4px;

  color: #4C4C4C;
}

.not-focused-label {
  font-family: "CathaySans_Rg";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #737373;

  position: absolute;
  height: 19px;
  left: 8px;
  top: calc(50% - 19px / 2 + 0.5px);
}

.not-focused-label-area {
  font-family: "CathaySans_Rg";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #737373;

  position: absolute;
  height: 19px;
  left: 8px;
  top: 20px;
}

.not-focused-label-disabled {
  background: transparent;
  cursor: default;

  font-family: "CathaySans_Rg";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #737373;

  position: absolute;
  height: 19px;
  left: 8px;
  top: calc(50% - 19px / 2 + 0.5px);
}

.focused-label {
  font-family: "CathaySans_Rg";
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #808285;
  background-color: #FFFFFF;

  position: absolute;
  height: 19px;
  width: 80%;
  left: 8px;
  right: 44px;
  top: 4px;
}

.unit {
  font-family: "CathaySans_Rg";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 19px;
  text-align: right;
  color: #2D2D2D;
  background-color: #FFFFFF;

  position: absolute;
  height: 19px;
  bottom: 7px;
  right: 8px;
  top: 22px;
}

.cx-error-message {
  margin: 7px 0px;
  font-family: "CathaySans_Rg";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;

  color: red;
}

@-moz-document url-prefix() {
  .not-focused-label, .not-focused-label-disabled {
    padding-top: 3px;
  }
}

/* ----------------------- Text Input Styles ------------------------ */
.cx-textinput {
  margin: 4px 0px;
  position: relative;
  z-index: 1040;
}

/* ----------------------- End of Text Input Styles ------------------------ */

/* ----------------------- Text Area Styles ------------------------ */
.cx-textarea {
  margin: 4px 0px;
  height: 144px;
  position: relative;
  z-index: 1040;
}

.cx-form-control-text-area {
  width: 100%;
  border-radius: 4px;
  position: relative;
  z-index: 0;
}

.cx-textarea-form-input {
  font-family: "RobotoMono-Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #2D2D2D;

  padding-top: 22px;
  padding-left: 8px;
  padding-bottom: 7px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #C6C2C1;
  border-radius: 4px;
  cursor: text;
  resize: none;
}

.cx-textarea-form-input-without-label {
  font-family: "RobotoMono-Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #737373;

  padding-top: 15px;
  padding-left: 8px;
  padding-bottom: 14px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #C6C2C1;
  border-radius: 4px;
  cursor: text;
  resize: none;
}

/* ----------------------- End of Text Area Styles ------------------------ */


/* ----------------------- Drop Down Styles ------------------------ */
.cx-dropdown {
  margin: 4px 0px;
  position: relative;
  z-index: 1050;
  cursor: pointer;
}

.cx-dropdown-readonly {
  background: #F2F2F3;
  cursor: default;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
}

.cx-dropdown .cx-form-input {
  cursor: pointer;
}

.cx-dropdown-body {
  padding: 8px 1px;
  border-top: 1px solid #E5E8EC;
  background: #FFFFFF;
  border: 1px solid #808285;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.36);
  border-radius: 2px;
  display: none;

  position: absolute;
  left: 0px;
  top: 56px;
  width: 164%;
}

.cx-dropdown-list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  max-height: 400px;
  overflow: scroll;
}

.cx-dropdown-item {
  font-family: "CathaySans_Rg";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4C4C4C;
  text-align: start;
  background: #FFFFFF;

  border: 0px;

  padding: 12px 16px;
  margin: 0px;
  height: 40px;
  width: 100%;

}

.cx-down-arrow-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 8px;
  top: 15px;
}

/* ----------------------- End of Drop Down Styles ------------------------ */

/* ----------------------- Datepicker Styles ------------------------ */
.cx-datepicker {
  position: relative;
  z-index: 1048;
  margin: 4px 0px;
  cursor: pointer;
  // border: 1px solid #b1b7c1;
  // border-radius: 0.25rem;
}

.cx-datepicker-body {
  background: #FFFFFF;
  border: 1px solid #C6C2C1;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
}

.cx-datepicker-body > .picker-input-group {
  height: 48px;
}

.cx-datepicker-body > .picker-input-group > .form-control, .cx-datepicker-body > .picker-input-group > .input-group-text {
  visibility: hidden;
}

.picker-input-group {
  opacity: 0;
  z-index: 1060;
}

.input-group-text {
  display: none;
}

.cx-datepicker-display {
  position: absolute;
  height: 48px;
  width: 77%;
  left: 44px;
  top: 0px;
}

.cx-datepicker-labels {
  left: 44px;
  width: 70%;
  background-color: transparent;
}

.selected-date {
  font-family: 'RobotoMono-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #4C4C4C;

  position: absolute;
  height: 19px;
  left: 44px;
  right: 8px;
  top: 22px;
}

.cx-calender-icon {
  position: absolute;
  left: 14px;
  top: 17px;
}

/* ----------------------- End of Datepicker Styles ------------------------ */

/* ----------------------- Toast Styles ------------------------ */


.cx-toast {
  backdrop-filter: blur(13.5914px);
  border-radius: 4px;
  width: fit-content;
}

.cx-success-toast {
  background: rgba(56, 126, 121, 0.95);
}

.cx-error-toast {
  background: rgba(171, 29, 29, 0.95);
}

.cx-toast-flex{
  align-items: center;
  width: 100%;
}

.cx-toast-body {
  font-family: "CathaySans_Rg";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  max-width: 404px;
}

.cx-toast-close{
  margin-left: 29px;
  margin-right: 20px;
}

.toast-container {
  padding: 0px !important;
  top: 144px !important;
  right: 2.6% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;  
}

/* ----------------------- Toast Styles ------------------------ */

/* ----------------------- Button Styles ------------------------ */
.cx-button, .cx-outline-button {
  margin: 4px 0px;
}

.spinner-border {
  height: 16px;
  width: 16px;
}

@-moz-document url-prefix() {
  .cx-button, .cx-outline-button {
    padding-top: 6px;
  }
}

/* ----------------------- Button Styles ------------------------ */


// Style of Status
.cx-form-input:focus, .focused {
  outline: none;
  border: 2px solid #4C4C4C !important;
}

.cx-dropdown-item:hover, .cx-dropdown-item.selected {
  background: #C6C2C1;
}

.cx-dropdown-item-disabled, .cx-dropdown-item-disabled:hover {
  background: #F2F2F3;
  cursor: default;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
}

.open {
  display: block;
}

.cx-disabled {
  background: #F2F2F3;
  cursor: default;
  border: 1px solid #C6C2C1;
  box-sizing: border-box;
  border-radius: 4px;
}

.cx-button:hover {
  opacity: 0.7;
}

.cx-button:disabled {
  background: #B9B9BB !important;
  border: 0px !important;
  border-radius: 8px !important;
  color: #FFFFFF !important;
}

.cx-outline-button:disabled {
  background: #FFFFFF !important;
  border: 2px solid #C9C9CC !important;
  border-radius: 8px !important;
  color: #C9C9CC !important;
}

.cx-button:disabled:hover {
  opacity: 1;
}

.chipInput::placeholder {
  opacity: 0;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
