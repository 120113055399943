// Font Variables
@font-face {
    font-family: "CathaySans_Bd";
    src: local("CathaySans_Bd"),
      url("../assets/fonts/CathaySans_Bd.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "CathaySans_BdIt";
    src: local("CathaySans_BdIt"),
      url("../assets/fonts/CathaySans_BdIt.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "CathaySans_It";
    src: local("CathaySans_It"),
      url("../assets/fonts/CathaySans_It.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "CathaySans_Lt";
    src: local("CathaySans_Lt"),
      url("../assets/fonts/CathaySans_Lt.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "CathaySans_LtIt";
    src: local("CathaySans_LtIt"),
      url("../assets/fonts/CathaySans_LtIt.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "CathaySans_Md";
    src: local("CathaySans_Md"),
      url("../assets/fonts/CathaySans_Md.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "CathaySans_MdIt";
    src: local("CathaySans_MdIt"),
      url("../assets/fonts/CathaySans_MdIt.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "CathaySans_Rg";
    src: local("CathaySans_Rg"),
      url("../assets/fonts/CathaySans_Rg.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
  font-family: "RobotoMono-Regular";
  src: local("RobotoMono-Regular"),
    url("../assets/fonts/RobotoMono-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoMono-Medium";
  src: local("RobotoMono-Medium"),
    url("../assets/fonts/RobotoMono-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoMono-Bold";
  src: local("RobotoMono-Bold"),
    url("../assets/fonts/RobotoMono-Bold.ttf") format("truetype");
  font-weight: normal;
}
