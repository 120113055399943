.form-check-label {
    padding-top: 3px;
}

.form-check-input[type='radio'],
.form-check-input~.form-check-label {
    cursor: pointer;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    opacity: 0.8;
    cursor: default;
}

.cx-radio-button {
    font-size: 14px;
    line-height: 18px;
    color: #4C4C4C;

    &.unchecked {
        font-family: 'CathaySans_Rg';
        font-weight: 400;
    }

    &.checked {
        font-family: 'CathaySans_Bd';
        font-weight: 700;
    }
}
