.carReportTopicPage {
    .searchBar {
        margin-top: 30px;

        .searchBox {
            width: 326px;
            margin-right: 20px;
        }

        .searchButton {
            width: 120px;
            height: 48px;
        }
    }

}

.tableContainer {
    .tableHead {
        &>tr {

            // border: 1px solid pink;
            &>th {
                padding: .5rem .5rem !important;
            }
        }
    }

    .tableBody {
        &>tr {
            border-bottom: 1px solid rgb(216, 216, 216);

            &>td {
                vertical-align: middle;
            }
        }
    }
}

.carReportTopicPageModal {
    .divider {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .tagsInputContainer {
        margin-top: unset;
        border: 1px solid #4C4C4C;
    }

    .modal-section-title {
        font-family: 'CathaySans_Rg';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #4C4C4C;
    }

    .modal-dialog {

        .modal-content {
            padding: 20px;
            min-height: 425px;

            .modal-header {
                border-bottom: none;
            }

            .modal-body {

                .cx-form-input {
                    border: 1px solid #4C4C4C;
                }

            }
        }
    }

    .errorBorder {

        input {
            border: 1px solid red !important;
        }
    }
}

.errorPlaceholder {
    margin: 0 7px 10px;
    font-family: "CathaySans_Rg";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: red;
    height: 16px;
}