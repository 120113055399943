.edg-scs-rate {
    padding: 16px;
    background-color: #F5F5F5;
    border-radius: 16px;
    color: #000000;
    align-self: center;

    &-title {
        font-family: 'CathaySans_Bd';
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }

    &-row-src-date {
        font-family: 'CathaySans_Rg';
        font-weight: 300;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

    &-row-src-value {
        font-family: 'CathaySans_Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 8px 0;
    }
}

