.reminder-dialog {
  display: flex;

  .modal-content {
    border: none;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-dialog {
    max-width: 78%;
  }

  .modal-item {
    background: transparent;
  }
}
