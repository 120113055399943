.edgModal {
    .modal-dialog {
        .modal-content {
            padding: 20px;
            // min-height: 1037px;
            .modal-header {
                border-bottom: none;   
                
                .modal-title{
                    font-family: 'CathaySans_Bd';
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    color: #4C4C4C;
                }
            }
            .modal-body {
                .edg-src-dtl {
                    font-family: 'CathaySans_Md';
                    font-style: normal;
                    font-weight: 500;
                    background: #F5F5F5;
                    border-radius: 8px;
                    padding: 20px;

                    &-title {
                        font-size: 18px;
                        line-height: 22px;
                        color: #4C4C4C;
                    }
                    
                    &-item-field {  
                        font-family: 'CathaySans_Rg';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 19px;
                        color: #808285;
                        margin-bottom: 4px;
                    }

                    &-item-value {
                        font-size: 14px;
                        line-height: 19px;
                        color: #2D2D2D;
                    }

                    &-item-filename {
                        color: #116F9A;
                        text-decoration-line: underline;
                    }

                    &-item-filename-status {
                        font-size: 12px;
                        line-height: 20px;                        
                        color: #FFFFFF;
                        border-radius: 4px;
                        padding: 2px 6px;       
                        
                        &-label {
                            margin-top: 2px;
                            margin-left: 2px;
                        }
                    }
                    
                    &-item-filename-status-match {
                        background: #669DA1;
                    }

                    &-item-filename-status-not-match {
                        background: #DA4B33;
                    }
                }
            }
        }
    }
}
