.flight-status-edg-col {  
    font-family: 'Cathay Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4C4C4C;

    &-view-link {
        color: #116F9A;
        cursor: pointer;
    }
}